<template>
  <div>
    <alliances-component />
    <footer-component />
  </div>
</template>

<script>
import AlliancesComponent from "@/components/alliances/AlliancesComponent";
import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";

export default {
  name: "Alliances",
  title: "Alianzas | Baja California Health Tourism",
  components: {
    FooterComponent,
    AlliancesComponent,
  },
};
</script>

<style scoped></style>
